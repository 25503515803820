<template>
  <v-container
    fluid
    tag="section"
    class="mt-1"
  >
    <v-card>
      <v-card-title class="card-title">
        <h4>{{ $t('dealers') }}</h4>
        <v-spacer />
        <v-btn
          color="primary"
          rounded
          small
          dark
          @click="addClick"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="mainHeaders"
        :items="filteredDealers"
        :items-per-page="filteredDealers.length"
        :footer-props="footerProps"
        :search="search"
        :single-expand="true"
        :expanded.sync="expanded"
        dense
        sort-by="Id"
        item-key="Id"
        fluid
        fixed-header
        style="cursor:pointer"
        class="elevation-1"
        @click:row="expand"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-row>
              <v-col
                cols="12"
                lg="3"
                md="3"
                sm="3"
                offset="8"
              >
                <v-select
                  v-model="filterDealerStatus"
                  :items="statuses"
                  item-text="title"
                  item-value="key"
                  dense
                  class="mt-6"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            />
            <manage-dealer
              v-if="dialog"
              :dialog="dialog"
              :dialog-type="dialogType"
              :clicked-dealer="selectedDealer"
              @dealer-dialog-closed="dialogClosed"
            />
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5">
                  {{ $t('areYouSureDeleteDealer') }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteDealerConfirm"
                  >
                    {{ $t('ok') }}
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                @click="editDealer(item)"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('editDealer') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                @click="deleteDealer(item)"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('deleteDealer') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length">
            <details-card
              :type="'dealers'"
              :details="detailedDealer"
            />
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import ManageDealer from '../Dialogs/ManageDealer.vue'
  import DetailsCard from '../Cards/DetailsCard.vue'
  export default {
    components: {
      ManageDealer,
      DetailsCard,
    },
    data: () => ({
      search: '',
      flag: true,
      dialog: false,
      dialogType: null,
      dialogEdit: false,
      dialogDelete: false,
      dealerToBeDelete: -1,
      filterDealerStatus: 'All',
      footerProps: { 'items-per-page-options': [-1, 5, 10, 15] },
      dealers: [],
      selectedDealer: {},
      expanded: [],
      statuses: [
        {
          key: 'All',
          title: 'All',
        },
        {
          key: 1,
          title: 'Active',
        },
        {
          key: 0,
          title: 'Inactive',
        },
      ],
    }),
    computed: {
      ...mapState({
        dealersFromRepo: (state) => state.dealer.dealers,
        detailedDealer: (state) => state.dealer.selectedDealer,
        verifiersFromRepo: (state) => state.verifier.verifiers,
      }),
      mainHeaders () {
        return [
          { text: this.$t('Id'), value: 'Id', align: 'start' },
          { text: this.$t('name'), value: 'Name' },
          { text: this.$t('contactPerson'), value: 'ContactPerson' },
          { text: this.$t('contactEmail'), value: 'ContactEmail' },
          { text: this.$t('contactPhone'), value: 'ContactPhone' },
          { text: this.$t('status'), value: 'statusForDisplay' },
          { text: this.$t('actions'), value: 'actions', sortable: false },
        ]
      // TODO FIX ROLE DATA TO ALL MODELS
      },
      filteredDealers () {
        let dealers = []
        switch (this.filterDealerStatus) {
          case 'All':
            dealers = this.dealers
            break
          case 1:
            dealers = this.dealers.filter(d => d.Status === 1)
            break
          case 0:
            dealers = this.dealers.filter(d => d.Status === 0)
            break
          default:
            dealers = this.dealers
            break
        }
        return dealers
      },
    },
    async mounted () {
      await this.getDealers()
    },
    methods: {
      addClick () {
        this.$nextTick(() => {
          this.dialog = true
        })
      },
      async getDealers () {
        this.loading = true
        await this.$store.dispatch('dealer/getDealer')
          .then(() => {
            this.dealers = this.dealersFromRepo
            const domain = 'DEALER_STATUS'
            this.$store.dispatch('verifier/getVerifiersForDomain', domain)
              .then(() => {
                this.verifiers = this.verifiersFromRepo
                this.dealers.forEach(dealer => {
                  const verifierForStatus = this.verifiers.filter(verifier => {
                    return Number(verifier.LowValue) === dealer.Status
                  })
                  dealer.statusForDisplay = verifierForStatus[0].ShortDescription
                })
              })
              .then(() => {
                this.loading = false
              })
          })
      },
      editDealer (dealer) {
        Object.assign(this.selectedDealer, dealer)
        this.dialogType = 'editDealer'
        this.dialog = true
      },

      deleteDealer (offer) {
        this.dealerToBeDelete = offer.Id
        this.dialogDelete = true
        this.flag = false
      },
      dialogClosed () {
        this.dialogEdit = false
        this.dialog = false
        this.getDealers()
        this.flag = true
      },
      closeDelete () {
        this.dealerToBeDelete = -1
        this.dialogDelete = false
        this.flag = true
      },
      deleteDealerConfirm () {
        this.flag = true
        this.$store.dispatch('dealer/deleteDealer', this.dealerToBeDelete)
          .then(res => {
            if (res.resFlag) {
              this.dialogDelete = false
              this.getDealers()
            }
          })
      },
      async expand (item) {
        await this.$store.dispatch('dealer/getDealerById', item.Id)
        if (item === this.expanded[0]) this.expanded = []
        else this.expanded = [item]
      },
    },
  }
</script>

<style lang="scss">
</style>
